<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit Seller</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name (Thai)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEnName" class="col-sm-2 col-form-label">Name (Eng) <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.en_name" @blur="validationRule()"
										class="form-control" id="inputEnName">
									<small class="text-danger">{{validationData.en_name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputEmail" class="col-sm-2 col-form-label">Email <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.email" @blur="validationRule()"
										class="form-control" id="inputEmail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputWhatsapp" class="col-sm-2 col-form-label">Whatsapp <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.whatsapp" @blur="validationRule()"
										class="form-control" id="inputWhatsapp">
									<small class="text-danger">{{validationData.whatsapp}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label">Address <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress">
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'sellerEdit',
		metaInfo: {
            title: "Seller Edit",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				filter: {
					page: "",
                    orderBy: "",
                    sortKey: "",
                    name: "",
				},
				dataRequest: {
					id: "",
					name: "",
					en_name: "",
					email: "",
					phone: "",
					whatsapp: "",
					address: ""
				},
				validationData: {
					name: "",
					en_name: "",
					email: "",
					phone: "",
					whatsapp: "",
					address: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				sellerUpdateAction: 'sellerUpdateAction',
				sellerDetailAction: 'sellerDetailAction'
			}),
			async getFields(){
				let option = {
					type: "update",
					id: this.dataRequest.id
				}
				await this.sellerDetailAction({
					...option
				}).then(res => {
					this.dataRequest.name = res.data.detail.name
					this.dataRequest.en_name = res.data.detail.en_name
					this.dataRequest.email = res.data.detail.email
					this.dataRequest.phone = res.data.detail.phone
					this.dataRequest.whatsapp = res.data.detail.whatsapp
					this.dataRequest.address = res.data.detail.address
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('id', this.dataRequest.id); 
				formData.append('name', this.dataRequest.name); 
				formData.append('en_name', this.dataRequest.en_name); 
				formData.append('email', this.dataRequest.email); 
				formData.append('phone', this.dataRequest.phone); 
				formData.append('whatsapp', this.dataRequest.whatsapp); 
				formData.append('address', this.dataRequest.address);  

				await this.sellerUpdateAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'seller-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.dataRequest.en_name == "") {
					this.validationData.en_name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.en_name = ""
					isValidation.push(false)
				}
				if (this.dataRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if (this.dataRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.dataRequest.wahtsapp == "") {
					this.validationData.wahtsapp = "The wahtsapp field is required."
					isValidation.push(true)
				} else {
					this.validationData.wahtsapp = ""
					isValidation.push(false)
				}
				if (this.dataRequest.address == "") {
					this.validationData.address = "The address field is required."
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
			this.dataRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>